// @ngInject
function contentDecorator($provide) {
  $provide.decorator(
    'content',
    /*@ngInject*/ function($delegate, $q, $log, contentSectionCache) {
      $delegate.loadSection = function(section) {
        var sectionData = contentSectionCache.get(section);

        if (!sectionData) {
          // I believe the reason Activation throws this error in ACP child scopes
          // (e.g. in-page-flow pages) is because this AngularJS app (Activation) is
          // inside another AngularJS app (ACP), which causes 'section' to be defined
          // as 'global', a global section sitting next to ACP's global section. So
          // let's ignore it.
          if (section !== 'global') {
            $log.error(
              'content.loadSection(): section is not pre-loaded into cache. ' +
                'Section must explicitly be put in cache using... ' +
                "contentSectionCache.put('" +
                section +
                "', require('content.yml'));"
            );
          }

          return $q.reject('REQUEST_FAILED');
        }

        var defer = $q.defer();
        defer.resolve(sectionData);
        return defer.promise;
      };

      return $delegate;
    }
  );
}

export default contentDecorator;
