//@ngInject
function eventBus(nsSignal) {
  var busCache = {};

  function getBus(event) {
    if (!busCache[event]) {
      busCache[event] = nsSignal.create();
    }

    return busCache[event];
  }

  return {
    emit: function(event, data) {
      if (!event) {
        throw new Error('cannot emit undefined event');
      }

      getBus(event).emit(data);
    },
    register: function(event, callback, $scope) {
      if (!event) {
        throw new Error('cannot register undefined event');
      }

      if ($scope) {
        $scope.$onValue(getBus(event), callback);
      } else {
        getBus(event).onValue(callback);
      }
    }
  };
}

export default eventBus;
