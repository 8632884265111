//@ngInject
function secretJSForm(nsUtil) {
  return function(config) {
    var form = document.createElement('form');
    form.setAttribute('method', config.method || 'POST');
    form.setAttribute('action', config.url);

    nsUtil.forOwn(config.data || {}, function(value, name) {
      var hiddenField = document.createElement('input');
      hiddenField.setAttribute('type', 'hidden');
      hiddenField.setAttribute('name', name);
      hiddenField.setAttribute('value', value);

      form.appendChild(hiddenField);
    });

    document.body.appendChild(form);

    form.submit();
  };
}

export default secretJSForm;
