function jsProxy() {
  /**
   * From MDN
   * You should not slice on arguments because it prevents optimizations in
   * JavaScript engines (V8 for example). Instead, try constructing a new
   * array by iterating through the arguments object.
   *
   * var args = Array.prototype.slice.call(arguments);
   *
   * @param args
   * @returns {Array}
   */
  function toArgsArray(args) {
    var argsArr = [];
    var i;

    for (i = 0; i < args.length; i++) {
      argsArr.push(args[i]);
    }

    return argsArr;
  }

  return function() {
    var context = this;
    var args = toArgsArray(arguments);
    var func = args.shift();

    var proxy = function() {
      return func.apply(context, args.concat(toArgsArray(arguments)));
    };

    return proxy;
  };
}

export default jsProxy;
