/*@ngInject*/
function simpleDeviceDetector() {
  // Mobile Devices
  var mobiles = {
    android: function(device) {
      return device.match(/Android/i) !== null;
    },
    blackberry: function(device) {
      return (
        device.match(/BlackBerry/i) !== null || device.match(/BB10/i) !== null
      );
    },
    ios: function(device) {
      return device.match(/iPhone|iPad|iPod/i) !== null;
    },
    opera: function(device) {
      return device.match(/Opera Mini/i) !== null;
    },
    windows: function(device) {
      return device.match(/IEMobile/i) !== null;
    },
    any: function(device) {
      return (
        this.android(device) ||
        this.blackberry(device) ||
        this.ios(device) ||
        this.opera(device) ||
        this.windows(device)
      );
    }
  };

  var deskops = {
    windows: function(device) {
      return (
        device.match(/MSIE/i) !== null || device.match(/Trident/i) !== null
      );
    }
  };

  return {
    isDesktop: function() {
      return !mobiles.any(this.getDevice());
    },

    isMobile: function() {
      return mobiles.any(this.getDevice());
    },

    isiOS: function() {
      return mobiles.ios(this.getDevice());
    },

    isAndroid: function() {
      return mobiles.android(this.getDevice());
    },

    isIE: function() {
      return deskops.windows(this.getDevice());
    },

    getDevice: function() {
      return navigator.userAgent;
    },

    mobiles: mobiles
  };
}

export default simpleDeviceDetector;
