export class Queue<T> {
  private elements: T[];

  constructor() {
    this.elements = [];
  }

  public peek(): T | null {
    return this.isEmpty() ? null : this.elements[0];
  }

  public show(): T[] | null {
    return this.isEmpty() ? null : this.elements;
  }

  public enqueue(item: T): void {
    this.elements.push(item);
  }

  public enqueueArray(arr: T[]): void {
    arr.forEach((item: T) => {
      this.enqueue(item);
    });
  }

  public dequeue(): T | null {
    if (this.isEmpty()) {
      return null;
    }

    return this.elements.shift();
  }

  public isEmpty(): boolean {
    return this.elements.length === 0;
  }

  public wipe(): void {
    this.elements = [];
  }

  public size(): number {
    return this.elements.length;
  }
}
