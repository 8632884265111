function activationConfig(nsConfig, ACTIVATION_CONFIGURATION) {
  return {
    get: function (key) {
      if (key !== 'mode') {
        // check tokens/env first
        var tokens = nsConfig.get('tokens');

        if (tokens) {
          var envConfig = tokens[this.getMode()];

          // Check ENV config
          if (envConfig && envConfig[key]) {
            return envConfig[key];
          }

          // Check Token Config
          if (tokens[key]) {
            return tokens[key];
          }
        }
      }

      // Check Global Config
      return nsConfig.get(key);
    },

    set: function (key, value) {
      nsConfig.set(key, value);
    },

    isProduction: function () {
      return this.getMode() === ACTIVATION_CONFIGURATION.VALUES.MODE_PRODUCTION;
    },

    isStandalone: function () {
      return (
        this.get(ACTIVATION_CONFIGURATION.KEYS.EXPERIENCE) ===
        ACTIVATION_CONFIGURATION.VALUES.EXPERIENCE_EXTERNAL
      );
    },

    isACP: function () {
      return (
        this.get(ACTIVATION_CONFIGURATION.KEYS.EXPERIENCE) ===
          ACTIVATION_CONFIGURATION.VALUES.EXPERIENCE_ACP ||
        this.get(ACTIVATION_CONFIGURATION.KEYS.EXPERIENCE) ===
          ACTIVATION_CONFIGURATION.VALUES.EXPERIENCE_AUTHENTICATED_ACP
      );
    },

    isAuthenticatedACP: function () {
      return (
        this.get(ACTIVATION_CONFIGURATION.KEYS.EXPERIENCE) ===
        ACTIVATION_CONFIGURATION.VALUES.EXPERIENCE_AUTHENTICATED_ACP
      );
    },

    getMode: function () {
      return this.get(ACTIVATION_CONFIGURATION.KEYS.MODE);
    },

    isMobileApp: function () {
      return (
        this.get(ACTIVATION_CONFIGURATION.KEYS.MOBILE) ===
        ACTIVATION_CONFIGURATION.VALUES.MOBILE_YES
      );
    },

    isPurpose: function (purpose) {
      return this.get(ACTIVATION_CONFIGURATION.KEYS.PURPOSE) === purpose;
    },

    isWeb: function () {
      return this.get(ACTIVATION_CONFIGURATION.KEYS.PLATFORM) === 'web';
    },

    isIos: function () {
      return this.get(ACTIVATION_CONFIGURATION.KEYS.PLATFORM) === 'ios';
    },

    isAndroid: function () {
      return this.get(ACTIVATION_CONFIGURATION.KEYS.PLATFORM) === 'android';
    },

    getRegistrationFingerprint: function () {
      return this.get(ACTIVATION_CONFIGURATION.KEYS.REGISTRATION_FINGERPRINT);
    }
  };
}

//@ngInject
function activationConfigProvider(nsConfigProvider, ACTIVATION_CONFIGURATION) {
  /**
   * Slurps the Config from window.nsConfig
   */
  function slurp() {
    var name;
    var config = window.activationConfig || {};

    for (name in config) {
      if (config.hasOwnProperty(name)) {
        nsConfigProvider.set(name, config[name]);
      }
    }
  }

  /**
   * Helper functions needed at .config time
   */
  this.isProduction = function () {
    return (
      nsConfigProvider.get(ACTIVATION_CONFIGURATION.KEYS.MODE) ===
      ACTIVATION_CONFIGURATION.VALUES.MODE_PRODUCTION
    );
  };

  this.get = function (key) {
    return nsConfigProvider.get(key);
  };

  this.set = function (key, value) {
    nsConfigProvider.set(key, value);
  };

  this.isMobileApp = function () {
    return (
      nsConfigProvider.get(ACTIVATION_CONFIGURATION.KEYS.MOBILE) ===
      ACTIVATION_CONFIGURATION.VALUES.MOBILE_YES
    );
  };

  /**
   * Build and Return the Service
   */
  //@ngInject
  this.$get = function (nsConfig) {
    return activationConfig(nsConfig, ACTIVATION_CONFIGURATION);
  };

  slurp();
}

export default activationConfigProvider;
