/**
 * Created by drenner on 7/28/15.
 */
//@ngInject
function elementScroller($timeout) {
  return {
    restrict: 'A',
    scope: {
      elementScroller: '='
    },
    link: function(scope, element) {
      var scrollerTimeout;

      function watch() {
        return scope.elementScroller;
      }

      function scroll() {
        var options = scope.elementScroller;

        if (options.when === true) {
          // Timeout is required
          scrollerTimeout = $timeout(function() {
            element[0].scrollIntoView((options.position || 'TOP') === 'TOP');
          }, 25);
        }
      }

      scope.$watch(watch, scroll);

      element.on('$destroy', function() {
        $timeout.cancel(scrollerTimeout);
      });
    }
  };
}

export default elementScroller;
