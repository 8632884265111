import nsObject from './ns-object';

//@ngInject
function log4js($log) {
  var Log4JS = nsObject.extend({
    _name: '',

    init: function(name) {
      this._name = name;
    },

    debug: function() {
      var args = ['DEBUG: ', this._name + ' '].concat(
        Array.prototype.slice.call(arguments)
      );
      $log.debug.apply(null, args);
    },

    info: function() {
      var args = ['INFO: ', this._name + ' '].concat(
        Array.prototype.slice.call(arguments)
      );
      $log.info.apply(null, args);
    },

    warn: function() {
      var args = ['WARN: ', this._name + ' '].concat(
        Array.prototype.slice.call(arguments)
      );
      $log.warn.apply(null, args);
    },

    error: function() {
      var args = ['ERROR: ', this._name + ' '].concat(
        Array.prototype.slice.call(arguments)
      );
      $log.error.apply(null, args);
    }
  });

  return {
    getLogger: function(name) {
      return new Log4JS(name);
    }
  };
}

export default log4js;
