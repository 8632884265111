import ng from 'angular';

//@ngInject
function storage($log) {
  var _storage = window.sessionStorage;

  var size = 0;
  var cache = {};
  var prefix = 'activation-';

  return {
    set: function(key, value) {
      if (this.get(key) === null || this.get(key) === '') {
        size++;
      }

      this._cache(key, value);
      this._store(key, value);
    },

    _cache: function(key, value) {
      cache[prefix + key] = value;
    },

    _store: function(key, value) {
      key = prefix + key;
      value = value || {};

      // Wrap it...
      value = ng.toJson({
        value: value
      });

      try {
        _storage.setItem(key, value);
      } catch (err) {
        $log.error('Session Storage is not available');
      }
    },

    get: function(key) {
      if (this._isCached(key)) {
        return this._loadFromCache(key);
      } else {
        return this._loadFromSession(key);
      }
    },

    _isCached: function(key) {
      return cache[prefix + key] !== undefined;
    },

    _loadFromCache: function(key) {
      return cache[prefix + key];
    },

    _loadFromSession: function(key) {
      key = prefix + key;
      var value = _storage.getItem(key) || '';

      if (value !== '') {
        value = ng.fromJson(value);
        return value.value;
      }

      return value;
    },

    reset: function() {
      cache = {};

      var i;
      var keysToEvict = [];

      for (i = 0; i < _storage.length; i++) {
        if (/activation-/g.test(_storage.key(i))) {
          keysToEvict.push(_storage.key(i));
        }
      }

      for (i = 0; i < keysToEvict.length; i++) {
        _storage.removeItem(keysToEvict[i]);
      }

      size = 0;
    },

    getSize: function() {
      return size;
    }
  };
}

export default storage;
