export default {
  KEYS: {
    /**
     * Version of the App
     */
    VERSION: 'version',
    /**
     * Brand of the App
     */
    BRAND: 'brand',
    /**
     * Platform running the App
     */
    PLATFORM: 'platform',
    /**
     * The mode of the application
     */
    MODE: 'mode',
    /**
     * Controls the experience of Activation
     */
    EXPERIENCE: 'experience',
    /**
     * Controls where Activation is running inside of Mobile
     */
    MOBILE: 'mobile',
    /**
     * Purpose of the flow
     */
    PURPOSE: 'purpose',
    /**
     * Flag to put the app in debug mode
     */
    DEBUG: 'debug',
    /**
     * URL to the native ACP forgot username page
     */
    ACP_FORGOT_PASSWORD_URL: 'acpForgotPasswordUrl',
    /**
     * URL to the native ACP forgot username page
     */
    ACP_FORGOT_USERNAME_URL: 'acpForgotUsernameUrl',
    /**
     * The URL to the mobile app in Google Play
     */
    PLAY_STORE_URL: 'playStoreUrl',
    /**
     * Thr URL to the mobile app in Apple's App Store
     */
    APP_STORE_URL: 'appStoreUrl',
    /**
     * Brand feature for showing if you can scan your card during registration
     */
    SHOW_SCAN_CARD_REGISTRATION: 'showScanCardRegistration',

    /**
     * Should the user be prompted to enter their external ID?
     */
    SET_EXTERNAL_ID: 'activationSetExternalId',
    /**
     * The JS regex used to validate the user's external ID
     */
    EXTERNAL_ID_FORMAT: 'activationExternalIdFormat',
    /**
     * Allows users to register for OAC access when PAN/CVC is not present
     * through a marketing email link (aka "Account Fingerprint").
     */
    REGISTRATION_FINGERPRINT: 'registrationFingerprint',
    /**
     * Users preference for Perso Card when feature OptionalPersoCard is enabled for account
     */
    OPTED_OUT_FOR_PERSO_CARD: 'optedOutForPersoCard'
  },
  VALUES: {
    /**
     * External Experience otherwise known as 'stand-alone'
     */
    EXPERIENCE_EXTERNAL: 'external',
    /**
     * Activation is hosted in Account Center Platform
     */
    EXPERIENCE_ACP: 'acp',
    /**
     * Activation is hosted in Account Center Platform and logged in
     */
    EXPERIENCE_AUTHENTICATED_ACP: 'authenticated-acp',
    /**
     * Activation is running inside of the Mobile App
     */
    MOBILE_YES: true,
    /**
     * Activation is not running inside of the Mobile App
     */
    MOBILE_NO: false,
    /**
     * Development Mode
     */
    MODE_DEVELOPMENT: 'development',
    /**
     * Production Mode
     */
    MODE_PRODUCTION: 'production',
    /**
     * Should the flow only be used to register an account online?
     */
    PURPOSE_REGISTRATION: 'registration',
    /**
     * Should the flow only be used to activate a card?
     */
    PURPOSE_ACTIVATION: 'activation',
    /**
     * Should the flow only be used to activate a card in a paywall flow?
     */
    PURPOSE_ACTIVATION_PAYWALL: 'activation-paywall'
  },
  FEATURES: {
    ANYTIME_ALERTS: 'webapi:activation_feature_anytimealerts',
    ESIGN: 'webapi:activation_feature_esign',
    EXTERNAL_REGISTRATION_DESIRED: 'external_registration_desired',
    OD_ENROLLMENT: 'webapi:activation_feature_overdraft_enrollment',
    PICK_YOUR_PLASTIC: 'webapi:activation_feature_pick_your_plastic',
    POINTS_EXTERNAL_REDEMPTION:
      'webapi:activation_feature_external_redemption_loyalty_enrollment',
    SET_EXTERNAL_ID: 'webapi:activation_feature_external_id',
    SELECT_FEE_PLAN: 'webapi:activation_fee_plan_selection_eligible'
  }
};
