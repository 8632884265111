import ng from 'angular';
import activationConfig from './activation-config';
import activationConfigurationConstants from './activation-configuration-constants';
import activationWebapiClient from './activation-webapi-client';
import contentDecorator from './content-decorator';
import validationErrors from './content/validation-errors.yml';
import elementScrollerDirective from './element-scroller-directive';
import eventBus from './event-bus';
import jsProxy from './js-proxy';
import log4Js from './log4js';
import nsDynamicFocus from './ns-dynamic-focus';
import secretJsForm from './secret-js-form';
import simpleDeviceDetector from './simple-device-detector';
import storage from './storage';

export * from './queue';

// Expose Types
export * from './activation-event-constants';
export * from './types';

const core = ng.module('components/activation/activation-ui/core', [
  'netspend.ui.utils.core',
  'netspend.ui.utils.content',
  'netspend.webclient'
]);

core.config(contentDecorator);

/**
 * Providers
 */
core.provider('activationConfig', activationConfig);

/**
 * Directives
 */
core.directive('elementScroller', elementScrollerDirective);
core.directive('nsDynamicFocus', nsDynamicFocus);

/**
 * Factories
 */
core.factory('activationWebapiClient', activationWebapiClient);
core.factory('log4js', log4Js);
core.factory('eventBus', eventBus);
core.factory('activationStorage', storage);
core.factory('simpleDeviceDetector', simpleDeviceDetector);
core.factory('jsProxy', jsProxy);
core.factory('secretJSForm', secretJsForm);

/**
 * Constants
 */
core.constant('ACTIVATION_CONFIGURATION', activationConfigurationConstants);

/**
 * Setup
 */
core.run(
  /*@ngInject*/ function (
    webapiResource,
    /* tslint:disable-next-line:no-shadowed-variable */
    activationConfig,
    contentSectionCache
  ) {
    if (!activationConfig.isACP()) {
      webapiResource.setUrl('/webapi');
      webapiResource.defaults.headers.common['X-NS-Client'] =
        'app=' +
        activationConfig.get('app') +
        '; ' +
        'platform=' +
        activationConfig.get('platform') +
        '; ' +
        'brand=' +
        activationConfig.get('brand') +
        '; ' +
        'version=' +
        activationConfig.get('version');

      webapiResource.defaults.headers.common[
        'X-NS-Variant'
      ] = `variant://${activationConfig.get('variant')}`;
    }

    contentSectionCache.put('validation-errors', validationErrors);
  }
);

export default core.name;
