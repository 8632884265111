export const ACTIVATION_EVENTS = {
  ACP_LOGIN_ACCESS_TOKEN_READY: 'activation.acp-login.access-token',
  ACP_LOGIN_REQUEST: 'activation.acp-login.request',
  EXTERNAL_AUTH_REQUIRED: 'activation.external_auth.required',
  FEATURE_ENROLLMENT_COMPLETE: 'feature.enrollment.steps.complete',
  GLOBAL_STATUS: 'activation.status.progress',
  REGISTER_WITH_FINGERPRINT_SUCCESS:
    'register.with.account-fingerprint.success',
  SET_DIRECT_DEPOSIT_SUCCESS: 'card.activation.set-direct-deposit.success',
  SET_SECURITY_QUESTION_FAILURE: 'register.set_security_question.failure',
  STATE_CHANGE_SUCCESS: 'activationStateChangeSuccess',
  TITLE_SET: 'activation.title.set',
  TITLE_RESET: 'activation.title.reset',
  ROUTE_HEADING_RESET: 'activation.route-heading.reset',
  UPDATE_PERSO_INFO_SUCCESS: 'missing.perso.info.success',
  UPDATE_PERSO_INFO_FAILURE: 'missing.perso.info.failure',
  ACTIVATION_BLOCK_QUEUE_EMPTY: 'activation.block-queue.empty',
  LAST_FOUR_SSN_VERIFIED: 'last-4-ssn.verified',

  CARD: {
    ACTIVATION_COMPLETE: 'card.activation.successful',
    ACTIVATION_FAILURE: 'card.activation.failure',
    BLOCK: 'card.activation.block',
    FATAL_ERROR: 'card.activation.unserviceable',

    ACTIVATE_FAILURE: 'card.activate.failure',
    ACTIVATE_SUCCESS: 'card.activate.success',

    ALREADY_REGISTERED: 'card.activation.already.registered',
    AUTH_EXPIRED: 'card.activation.auth_expired',
    INCORRECT_BRAND: 'card.activation.incorrect_brand',
    NOT_REGISTERED: 'card.activation.not_registered',
    PASSWORD_AUTH: 'card.activation.password_auth',
    PASSWORD_AUTH_NON_BLOCK: 'card.activation.password_auth_non_block',
    REDIRECT_TO_ACP_LOGIN_REQUEST:
      'card.activation.already.registered.continue',
    SET_PIN_SUCCESS: 'card.activate.set_pin.success',
    SET_PIN_FAILURE: 'card.activate.set_pin.failure',
    VERIFY_CIP: 'card.activation.verify_cip',
    VERIFY_CIP_SUCCESS: 'card.activation.verify_cip.success',
    VERIFY_CIP_FAILURE: 'card.activation.verify_cip.failure',
    ID_SCAN_SUCCESS: 'card.activation.id_scan.success',
    ID_SCAN_FAILED: 'card.activation.id_scan.failed',

    // User Preference for Optional Perso Card
    OPTIONAL_PERSO_CARD_PREF: 'optional.perso.card.pref',

    // Upgrade (DDA)
    UPGRADE_CARD: 'card.activation.upgrade_card',

    // Upgrade (Hub) events
    ACTIVATE_AND_UPGRADE_ACCOUNT: {
      SUCCESS: 'card.activation.account_upgrade.success',
      FAILURE: 'card.activation.account_upgrade.failure',
      FAILURE_CONTINUE: 'card.activation.account_upgrade.failure.continue'
    },

    CHECK_EMAIL: 'card.activation.check_email',
    UPGRADABLE_EMAIL_FOUND: 'card.activation.upgradable_email.found',
    UPGRADABLE_EMAIL_NOT_FOUND: 'card.activation.upgradable_email.not_found'
  },

  REGISTER: {
    SUCCESS: 'register.success',
    FAILED: 'register.failed',
    FAILURE: 'register.failure'
  },

  IDENTITY_QUESTIONS: {
    NEXT_AVAILABLE: 'identity.questions.next.available',
    LOAD_FAILURE: 'identity.questions.loaded.failure'
  },

  PASSWORD_AUTH: {
    LOGIN_SUCCESS: 'password.auth.login.success',
    LOGIN_FAILURE: 'password.auth.login.failure',
    ACTIVATE_SUCCESS: 'password.auth.activate.success',
    ACTIVATE_FAILURE: 'password.auth.activate.failure'
  },

  SET_PIN: {
    ACTIVATE_SUCCESS: 'set_pin.activate.success',
    ACTIVATE_FAILURE: 'set_pin.activate.failure'
  },

  MISSING_BUSINESS_INFO: {
    FAILURE: 'missing.business.info.failure'
  },

  ANSWERED_EXPECTED_USE_QUESTIONS: {
    SUCCESS: 'answered.expected.use.questions.success'
  },

  ESIGN_AGREEMENT: {
    ACCEPTED: 'esign_agreement.accepted'
  },

  // Feature Enrollment events
  FEATURES: {
    ANYTIME_ALERTS: {
      SUCCESS: 'feature.anytimealerts.success'
    },

    ESIGN: {
      SUCCESS: 'feature.esign.success',
      SKIPPED: 'feature.esign.skipped'
    },

    EXTERNAL_REGISTRATION: {
      SKIPPED: 'feature.external_registration.skipped'
    },

    FEE_PLAN: {
      RETRIEVE_SUCCESS: 'fee_plan_retrieve.success',
      RETRIEVE_FAILURE: 'fee_plan_retrieve.failure',
      RETRIEVE_CURRENT_SUCCESS: 'fee_plan_retrieve_current.success',
      RETRIEVE_CURRENT_FAILURE: 'fee_plan_retrieve_current.failure',
      SUBSCRIBE_SUCCESS: 'fee_plan_subscribe.success',
      SUBSCRIBE_FAILURE: 'fee_plan_subscribe.failure',

      GET_CARD_HOLDER_AGREEMENT_SUCCESS:
        'get_card_holder_agreement_link.success',
      GET_CARD_HOLDER_AGREEMENT_FAILURE:
        'get_card_holder_agreement_link.failure',
      GET_LOYALTY_UNIT_SUCCESS: 'get_loyalty_unit_type.success',
      GET_LOYALTY_UNIT_FAILURE: 'get_loyalty_unit_type.failure'
    },

    OVERDRAFT: {
      SUCCESS: 'feature.overdraft.success'
    },

    PICK_YOUR_PLASTIC: {
      SUCCESS: 'feature.pick_your_plastic.success'
    },

    POINTS_EXTERNAL_REDEMPTION: {
      SUCCESS: 'feature.points-external-redemption.success'
    },

    SET_EXTERNAL_ID: {
      SUCCESS: 'set.external.id.success',
      FAILURE: 'set.external.id.failure'
    },
    DDA: {
      OOBA_FLOW_ENABLED: 'feature.flow.enabled',
      OOBA_SUCCESS: 'feature.success'
    }
  }
};

// TODO change to use a DeepReadonly type in Typescript 2.8 (instead of freeze)
// https://github.com/Microsoft/TypeScript/issues/13923

function deepFreeze(o) {
  Object.freeze(o);

  Object.getOwnPropertyNames(o).forEach((prop) => {
    if (
      o.hasOwnProperty(prop) &&
      o[prop] !== null &&
      (typeof o[prop] === 'object' || typeof o[prop] === 'function') &&
      !Object.isFrozen(o[prop])
    ) {
      deepFreeze(o[prop]);
    }
  });

  return o;
}

deepFreeze(ACTIVATION_EVENTS);
