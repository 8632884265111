import { ACTIVATION_EVENTS } from './activation-event-constants';

import nsObject from './ns-object';

// @ngInject
function activationWebapiClient(
  log4js,
  $q,
  webapiResource,
  nsUtil,
  activationStorage,
  eventBus
) {
  var STORAGE_KEY = 'access.token';
  var logger = log4js.getLogger('activationWebapiClient');
  var WebapiClient = nsObject.extend({
    // HTTP Methods
    _POST: 'POST',
    _GET: 'GET',
    _PUT: 'PUT',
    _DELETE: 'DELETE',

    // Config
    _url: null,
    _format: null,
    _cache: null,
    _withToken: null,
    _timeout: 0,

    // Cached Methods
    _getClient: null,
    _postClient: null,
    _putClient: null,
    _deleteClient: null,

    init: function(url, config) {
      this._url = url;
      this._format = config.format;
      this._cache = config.cache || false;
      this._withToken = config.withToken || true;
      this._ignoreErrors = config.ignoreErrors || false;
      this._timeout = config.timeout || 20000;
    },

    $get: function(data) {
      var method = this._GET;
      var startTime = new Date();

      if (!this._getClient) {
        this._getClient = this._constructClient(this._GET);
      }

      var success = this.proxy(this._success, method, startTime);
      var error = this.proxy(this._error, method, startTime);

      logger.debug('Executing ' + method + ' against ' + this._url);
      return this._getClient(data || {}).then(success, error);
    },

    $post: function(data) {
      var method = this._POST;
      var startTime = new Date();

      if (!this._postClient) {
        this._postClient = this._constructClient(this._POST);
      }

      var success = this.proxy(this._success, method, startTime);
      var error = this.proxy(this._error, method, startTime);

      logger.debug('Executing ' + method + ' against ' + this._url);
      return this._postClient(data || {}).then(success, error);
    },

    $put: function(data) {
      var method = this._PUT;
      var startTime = new Date();

      if (!this._putClient) {
        this._putClient = this._constructClient(method);
      }

      var success = this.proxy(this._success, method, startTime);
      var error = this.proxy(this._error, method, startTime);

      logger.debug('Executing ' + method + ' against ' + this._url);
      return this._putClient(data || {}).then(success, error);
    },

    $delete: function(data) {
      var method = this._DELETE;
      var startTime = new Date();

      if (!this._deleteClient) {
        this._deleteClient = this._constructClient(method);
      }

      var success = this.proxy(this._success, method, startTime);
      var error = this.proxy(this._error, method, startTime);

      logger.debug('Executing ' + method + ' against ' + this._url);
      return this._deleteClient(data || {}).then(success, error);
    },

    _success: function(method, startTime, response) {
      logger.debug(
        method +
          ' executed against ' +
          this._url +
          ' in ' +
          (new Date() - startTime) +
          'ms'
      );
      return response;
    },

    _error: function(method, startTime, response) {
      logger.error(
        method +
          ' failed to execute against ' +
          this._url +
          ' after ' +
          (new Date() - startTime) +
          'ms'
      );

      if (response._server_errors && response._server_errors.length > 0) {
        nsUtil.forEach(response._server_errors, function(key) {
          if (key.indexOf('global.') !== -1) {
            response._server_errors.push(key.replace('global.', ''));
          }
        });

        // check for unknown
        if (response._server_errors.indexOf('server.unknown') !== -1) {
          // server is down!
          logger.error('API is down!');
          eventBus.emit(ACTIVATION_EVENTS.CARD.FATAL_ERROR);
        }
      }

      var data = {
        payload: response,
        serverErrors: response._server_errors,
        fieldErrors: nsUtil.getFieldErrors(response)
      };

      logger.debug(JSON.stringify(data));

      return $q.reject(data);
    },

    _constructClient: function(method) {
      logger.debug(
        'Constructing WEBAPI Client for ' + this._url + ' and method ' + method
      );

      return webapiResource({
        path: this._url,
        method: (method || 'GET').toUpperCase(),
        format: this._format,
        cache: this._cache,
        withToken: this._withToken,
        ignoreErrors: this._ignoreErrors,
        timeout: this._timeout
      });
    }
  });

  function init() {
    var accessToken = activationStorage.get(STORAGE_KEY);

    if (accessToken && accessToken !== '') {
      logger.debug('Loading stored access token:', accessToken);
      setAccessToken(accessToken);
    }
  }

  function setAccessToken(accessToken) {
    logger.debug('Setting access token:', accessToken);
    webapiResource.setToken(accessToken);
    activationStorage.set(STORAGE_KEY, accessToken);
  }

  init();

  return {
    createClient: function(url, options) {
      return new WebapiClient(url, options || {});
    },

    setAccessToken: function(accessToken) {
      setAccessToken(accessToken);
    }
  };
}

export default activationWebapiClient;
