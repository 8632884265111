import './simple-javascript-inheritance';

/**
 * Base class for 'fake' inheritance
 */
var nsObject = window.Class.extend({
  init: function() {},

  proxy: function() {
    var context = this;
    var args = Array.prototype.slice.call(arguments);
    var func = args.shift();

    var proxy = function() {
      return func.apply(
        context,
        args.concat(Array.prototype.slice.call(arguments))
      );
    };

    return proxy;
  }
});

export default nsObject;
