//@ngInject
function nsDynamicFocus($timeout) {
  return {
    restrict: 'A',
    link: function(scope, el, attrs) {
      scope.$watch(attrs.nsDynamicFocus, function(shouldFocus) {
        if (shouldFocus) {
          $timeout(function() {
            var input = el[0].querySelector('input');

            if (input) {
              input.focus();
            }
          });
        }
      });
    }
  };
}

export default nsDynamicFocus;
